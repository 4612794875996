import React from "react";
import Hero from "./Hero";
import Enfoque from "./Enfoque";
import Unicos from "./Unicos";
import Servicios from "./Servicios";
import Clientes from "./Clientes";
import Equipo from "./Equipo";

const Home = () => {
  return (
    <>
      <Hero />
      <Enfoque />
      <Unicos />
      <Servicios />
      <Clientes />
      <Equipo />
    </>
  );
};

export default Home;
