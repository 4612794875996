import React, { useEffect, useState } from "react";
import { supabaseClient } from "../config/supabase";
import EquipoCard from "./EquipoCard";
import { Link } from "react-router-dom";

const Equipo = () => {
  const [equipoData, setEquipoData] = useState([]);

  useEffect(() => {
    const fetchEquipo = async () => {
      const { data, error } = await supabaseClient
        .from("equipo")
        .select("*")
        .order("id", { ascending: true });
      if (error) console.log("Error fetching equipo data:", error.message);
      else setEquipoData(data);
    };
    fetchEquipo();
  }, []);

  return (
    <div className="bg-white h-[80vh] relative overflow-hidden px-4 py-6">
      <div className="flex flex-col md:flex-row mt-5">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-purple26N font-ibm-plex">
            Equipo
          </h6>
        </div>
        <div className="basis-3/4">
          <h2 className="text-black font-druk-wide font-bold uppercase text-2xl md:text-4xl ">
            Somos lo que contamos
          </h2>
        </div>
      </div>
      <div className="flex flex-row items-start justify-start mt-6 overflow-x-auto space-x-4">
        {equipoData.slice(0, 5).map((miembro, index) => (
          <EquipoCard
            key={index}
            nombre={miembro.nombre}
            puesto={miembro.puesto}
            image={miembro.img_url}
          />
        ))}
        <div className="mx-2 flex-shrink-0 w-60 rounded-xl">
          {" "}
          <div className="w-60 h-60 bg-purple26N rounded-xl px-2 py-2">
            <div className="relative h-full">
              <svg
                className="absolute top-0 right-0"
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M32.9578 32.086L33.224 0C25.1873 0.078 9.1748 0.188113 1.1379 0.266113L1.0719 4.28296C8.3006 4.21196 17.897 4.15302 26.3391 4.07642L0 30.4153L2.809 33.2243L29.1481 6.88531L28.9196 32.1306L32.9578 32.086Z"
                  fill="#F0F3FA"
                />
              </svg>

              <Link
                to="/nosotros"
                className="bottom-0 left-0 text-h5-desktop text-white font-ibm-plex"
              >
                Conocer más
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Equipo;
