import React from "react";
import { Link } from "react-router-dom";
import logoExpandido from "../images/logo_footer.png";

const Footer = () => {
  return (
    <div className="bg-black text-white p-6 md:p-12">
      <div className="flex flex-col md:flex-row justify-center align-center items-start gap-8 md:gap-0">
        <h2 className="text-white font-druk-wide font-bold uppercase text-3xl md:text-h2-desktop  mb-4 md:mb-0 ">
          Contáctanos y hagamos que tu historia se vea
        </h2>
        <Link
          to="/contacto"
          className="bg-transparent border border-white min-w-[200px] py-3 rounded-full hover:bg-purple26N hover:border-purple26N  text-center"
        >
          EMPEZAR PROYECTO
        </Link>
      </div>
      <div className="flex flex-col md:flex-row justify-between items-center md:items-start  mt-8">
        <div className="basis-full md:basis-1/3 flex justify-center md:justify-start">
          <img
            src={logoExpandido}
            alt="Logo"
            className="w-1/2 md:w-1/2  object-contain"
          />
        </div>
        <div className="basis-full md:basis-3/4 flex flex-col md:flex-row justify-between mt-8 md:mt-0">
          <div className="text-center md:text-left mb-8 md:mb-0">
            <h5 className="text-lg font-bold mb-2">Contacto</h5>
            <a
              href="mailto:atencion@26norte.mx"
              className="font-ibm-plex text-p-desktop leading-tight"
            >
              atencion@26norte.mx
            </a>
          </div>
          <div className="text-center md:text-left mb-8 md:mb-0">
            <h5 className="text-lg font-bold mb-2">Oficina Central</h5>
            <p className="mt-2 font-ibm-plex text-p-desktop">
              Símon Bolívar 276,{" "}
            </p>
            <p className="mt-2 font-ibm-plex text-p-desktop">
              Col. Obispado, Monterrey
            </p>
            <p className="mt-2 font-ibm-plex text-p-desktop">
              Nuevo León, México
            </p>
          </div>
          <div className="text-center flex flex-col md:text-left mb-8 md:mb-0">
            <h5 className="text-lg font-bold mb-2">Social</h5>
            <a
              href="https://www.instagram.com/26norte.mx/"
              target="_blank"
              rel="noreferrer"
              className="font-ibm-plex text-p-desktop mt-2"
            >
              Instagram
            </a>
            <a
              href="https://www.linkedin.com/company/26norte"
              target="_blank"
              rel="noreferrer"
              className="font-ibm-plex text-p-desktop mt-2"
            >
              LinkedIn
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
