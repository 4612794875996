import sensibilidadSocial from "../../images/sensibilidad.png";
import sentidoPeriodistico from "../../images/periodistico.png";
import capacidadNarrativa from "../../images/narrativa.png";
import ojoDiseño from "../../images/ojo.png";
import s1 from "../../images/servicios/s1.jpg";
import s2 from "../../images/servicios/s2.jpg";
import s3 from "../../images/servicios/s3.png";
import s4 from "../../images/servicios/s4.png";
import s5 from "../../images/servicios/s5.jpg";
import s6 from "../../images/servicios/s6.jpg";
import s7 from "../../images/servicios/s7.png";
import s8 from "../../images/servicios/s8.jpg";
import s9 from "../../images/servicios/s9.gif";
import s10 from "../../images/servicios/s10.png";
import sc1 from "../../images/showcase/sc1.png";
import sc2 from "../../images/showcase/sc2.png";
import sc3 from "../../images/showcase/sc3.png";
import sc4 from "../../images/showcase/sc4.png";
import sc5 from "../../images/showcase/sc5.png";
import sc6 from "../../images/showcase/sc6.png";

export const serviciosData = [
  {
    id: 1,
    title: "STORYTELLING",
    description: "para empresas, gobiernos, marcas y productos.",
    imageUrl: s1,
  },
  {
    id: 2,
    title: "PRESENTACIONES Y DISCURSOS",
    description: "para eventos, líderes y personajes públicos.",
    imageUrl: s2,
  },
  {
    id: 3,
    title: "CONCEPTO CREATIVO",
    description: "para campañas, programas públicos y proyectos privados.",
    imageUrl: s3,
  },
  {
    id: 4,
    title: "BRANDING",
    description: "y aplicaciones de marca.",
    imageUrl: s4,
  },
  {
    id: 5,
    title: "EDITORIAL",
    description:
      "desde coffee table books, hasta informes anuales para sector público y privado.",
    imageUrl: s5,
  },
  {
    id: 6,
    title: "VIDEO",
    description:
      "desde spots, documentales, corporativos e informes anuales hasta entrevistas.",
    imageUrl: s6,
  },
  {
    id: 7,
    title: "WEB",
    description: "desde sitios oficiales y micrositios, hasta info stories.",
    imageUrl: s7,
  },
  {
    id: 8,
    title: "CONTENIDO DIGITAL",
    description: "creativo para redes sociales.",
    imageUrl: s8,
  },
  {
    id: 9,
    title: "GRÁFICOS ESPECIALIZADOS",
    description: "como visualización de datos, ilustración y motion graphics.",
    imageUrl: s9,
  },
  {
    id: 10,
    title: "EVENTOS DE IMPACTO",
    description:
      " incluyendo concepto, desarrollo de contenido, diseño de presentaciones, producción de videos y coordinación general.",
    imageUrl: s10,
  },
];

export const images = {
  sensibilidadSocial,
  sentidoPeriodistico,
  capacidadNarrativa,
  ojoDiseño,
};

export const showCaseImages = [sc1, sc2, sc3, sc4, sc5, sc6];

export const gridVariations = [
  [
    { colSpan: 2, rowSpan: 1, position: { col: 1, row: 1 } },
    { colSpan: 1, rowSpan: 2, position: { col: 3, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 1, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 2, row: 2 } },
    { colSpan: 2, rowSpan: 1, position: { col: 3, row: 2 } },
  ],

  [
    { colSpan: 1, rowSpan: 2, position: { col: 1, row: 1 } },
    { colSpan: 2, rowSpan: 1, position: { col: 2, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 1 } },
    { colSpan: 2, rowSpan: 1, position: { col: 2, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 1, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 2 } },
  ],

  [
    { colSpan: 2, rowSpan: 1, position: { col: 1, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 3, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 1 } },
    { colSpan: 1, rowSpan: 2, position: { col: 1, row: 2 } },
    { colSpan: 2, rowSpan: 1, position: { col: 2, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 2 } },
  ],

  [
    { colSpan: 1, rowSpan: 2, position: { col: 1, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 2, row: 1 } },
    { colSpan: 2, rowSpan: 1, position: { col: 3, row: 1 } },
    { colSpan: 2, rowSpan: 1, position: { col: 1, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 3, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 2 } },
  ],

  [
    { colSpan: 1, rowSpan: 1, position: { col: 1, row: 1 } },
    { colSpan: 2, rowSpan: 1, position: { col: 2, row: 1 } },
    { colSpan: 1, rowSpan: 1, position: { col: 4, row: 1 } },
    { colSpan: 1, rowSpan: 2, position: { col: 2, row: 2 } },
    { colSpan: 1, rowSpan: 1, position: { col: 1, row: 2 } },
    { colSpan: 2, rowSpan: 1, position: { col: 3, row: 2 } },
  ],
];
