import React from "react";
import { Link } from "react-router-dom";
import potrerillos1 from "../images/potrerillos1.png";

const Enfoque = () => {
  return (
    <div className="bg-white min-h-screen md:h-[90vh] relative overflow-hidden flex flex-col md:flex-row px-4 py-6 mt-5">
      <div className="basis-1/4">
        <h6 className="text-h6-desktop text-purple26N font-ibm-plex">
          Enfoque
        </h6>
      </div>
      <div className="basis-3/4">
        <h4 className="text-h4-desktop text-black font-ibm-plex">
          Con finura y pasión, unimos el poder de las narrativas con la potencia
          de las imágenes para crear productos memorables.
        </h4>
        <h4 className="text-h4-desktop text-black font-ibm-plex mt-8">
          Así conectamos tu historia con quienes necesitan conocerla.
        </h4>
        <img
          src={potrerillos1}
          className="w-[100vh] h-56 mt-6 cover"
          alt="imagen_equipo"
        ></img>
        <div className="mt-8 mb-6 text-h5-desktop text-black font-ibm-plex flex items-center">
          <h5>¿Y por qué nos llamamos así?</h5>
          <span className="mx-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 100 24"
              stroke="currentColor"
              className="w-20 h-5"
            >
              <line
                x1="0"
                y1="12"
                x2="80"
                y2="12"
                stroke="currentColor"
                strokeWidth="2"
              />
              <polygon points="85,12 75,6 75,18" fill="currentColor" />
            </svg>
          </span>
          <Link to="/nosotros" className="hover:text-purple26N underline">
            {" "}
            Te contamos{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Enfoque;
