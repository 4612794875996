import React, { useState } from "react";
import { serviciosData } from "./utils/utils";
import { Link } from "react-router-dom";

const Servicios = () => {
  const [hoveredItem, setHoveredItem] = useState(null);

  return (
    <div className="bg-white min-h-screen">
      <div className="bg-black  md:h-[150vh] relative rounded-xl overflow-hidden flex flex-col px-6 md:px-6 py-6">
        <div className="flex flex-col md:flex-row mt-5">
          <div className="basis-1/4">
            <h6 className="text-h6-desktop text-green26N font-ibm-plex">
              Servicios
            </h6>
          </div>
          <div className="basis-3/4">
            <h2 className="text-white font-druk-wide font-bold uppercase text-2xl md:text-4xl">
              Historias que toman forma
            </h2>
          </div>
        </div>
        <div className="w-full mt-4 md:mt-6 relative">
          <ul className="pb-10">
            {serviciosData.map((item) => (
              <li
                key={item.id}
                className={`border-b  py-4 text-white relative ${
                  hoveredItem?.id === item.id ? "bg-green26N" : "bg-black"
                } ${
                  hoveredItem?.id === item.id ? "border-black" : "border-white"
                }`}
                onMouseEnter={() => setHoveredItem(item)}
                onMouseLeave={() => setHoveredItem(null)}
              >
                <div className="flex flex-col md:flex-row">
                  <h4
                    key={`h4+${item.id}`}
                    className={`w-full md:w-1/2  font-ibm-plex text-2xl md:text-h4-desktop ${
                      hoveredItem?.id === item.id ? "text-black" : "text-white"
                    }`}
                  >
                    {item.title}
                  </h4>
                  <p
                    className={`w-full md:w-1/2 text-start md:text-right text-p-desktop font-ibm-plex transition-opacity duration-300 ease-in-out ${
                      hoveredItem?.id === item.id ? "text-black" : "text-white"
                    }`}
                  >
                    {item.description}
                  </p>
                </div>

                {hoveredItem?.id === item.id && (
                  <img
                    src={item.imageUrl}
                    alt={item.title}
                    className="absolute top-0 left-1/2 transform -translate-x-1/2 -translate-y-full z-10 object-cover"
                    style={{
                      left: `calc(70% - 100px)`,
                      top: `calc(100%)`,
                      width: "200px",
                      height: "200px",
                    }}
                  />
                )}
              </li>
            ))}
          </ul>
        </div>
        <div className="flex justify-center">
          <Link
            to="/contacto"
            className="bg-transparent border border-white min-w-[300px] py-3 rounded-full hover:bg-purple26N hover:border-purple26N  text-center text-white"
          >
            SOLICITA NUESTRO PORTAFOLIO
          </Link>
        </div>
        {/* <AnimatedGrid /> */}
      </div>
    </div>
  );
};

export default Servicios;
