import React, { useState } from "react";
import { images } from "./utils/utils";
import { motion } from "framer-motion";

const Unicos = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const sections = [
    {
      title: "Sensibilidad Social",
      content: "Para entender temas complejos y realidades cambiantes",
      image: "sensibilidadSocial",
    },
    {
      title: "Sentido Periodistico",
      content: "Para curar la información",
      image: "sentidoPeriodistico",
    },
    {
      title: "Capacidad narrativa",
      content: "Para contar grandes historias",
      image: "capacidadNarrativa",
    },
    {
      title: "Ojo de diseño",
      content: "Para crear visuales impactantes",
      image: "ojoDiseño",
    },
  ];

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  return (
    <div className="bg-white min-h-screen md:min-h-[75vh]  relative overflow-hidden  px-4 py-6">
      <div className="flex flex-row">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-purple26N font-ibm-plex">
            Lo que nos hace únicos
          </h6>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center">
        <div className="basis-1/4 flex justify-center items-center">
          {activeIndex !== null && (
            <motion.img
              key={sections[activeIndex].image}
              src={images[sections[activeIndex].image]}
              alt={sections[activeIndex].title}
              className="mt-4 w-55 h-34 md:w-111 md:h-69"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.8 }}
              transition={{
                duration: 0.5,
                ease: "easeInOut",
              }}
            />
          )}
        </div>
        <div className="basis-3/4 md:mt-[10vh]">
          {sections.map((section, index) => (
            <div key={index}>
              <h3
                className={`text-3xl md:text-h3-desktop uppercase font-druk-wide mt-3 cursor-pointer ${
                  activeIndex === index
                    ? "text-purple26N"
                    : "text-gray26N hover:text-pruple26N"
                }`}
                onClick={() => toggleSection(index)}
              >
                {section.title}
              </h3>
              <div
                className={`overflow-hidden transition-all duration-500 ease-in-out ${
                  activeIndex === index
                    ? "max-h-[100px] opacity-100"
                    : "max-h-0 opacity-0"
                }`}
              >
                <p className="mt-2 text-black text-h6-desktop font-ibm-plex ">
                  {section.content}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Unicos;
