import React from "react";
import inspira1 from "../images/inspira1.png";
import inspira2 from "../images/inspira2.png";

const Inspira = () => {
  return (
    <div className="bg-black min-h-screen md:min-h-[130vh] relative overflow-hidden flex flex-col justify-center  px-4 pb-6 pt-16">
      <div className="flex flex-col md:flex-row">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-pink26N font-ibm-plex">
            Lo que nos inspira
          </h6>
        </div>
        <div className="basis-3/4">
          <h2 className="text-white font-druk-wide font-bold uppercase text-h2 md:text-h2-desktop ">
            26 grados al norte
          </h2>
          <h4 className="text-h4-desktop text-white font-ibm-plex mt-3">
            descansan, entre el desierto y el silencio, piedras que relatan otro
            tiempo: soles, ondas de agua, líneas que se disparan al universo…
          </h4>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center gap-4 md:gap-4 mt-8">
        <div className="basis-1/2 flex justify-center">
          <img
            src={inspira1}
            className="h-[60vh] w-full object-cover"
            alt="imagen_equipo"
          />
        </div>
        <div className="basis-1/2 flex justify-center">
          <img
            src={inspira2}
            className="h-[60vh] w-full object-cover cover"
            alt="imagen_equipo"
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="basis-3/4 ml-auto text-white">
          <h4 className="text-h4-desktop text-white font-ibm-plex mt-3">
            Un eco que recorre más de 7 mil años nos inspira: los petrograbados
            del sitio arqueológico monumental “Boca de Potrerillos”, en Mina,
            Nuevo León.
          </h4>
          <h5 className="text-h5-desktop text-white font-ibm-plex mt-9">
            Es una historia milenaria que casi se desvanece, pero que sobrevivió
            en imágenes.
          </h5>
          <h5 className="text-h5-desktop text-white font-ibm-plex mt-6">
            Nos mueve la convicción de que las historias tienen el poder de
            trascender en el tiempo, conectarnos e impulsar grandes cambios.
          </h5>
        </div>
      </div>
      <div className="flex flex-col md:flex-row">
        <div className="basis-5/12 ml-auto text-white"></div>
      </div>
    </div>
  );
};

export default Inspira;
