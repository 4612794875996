import React from "react";

const EquipoCard = ({ nombre, puesto, dark, image }) => {
  return (
    <div className="mx-2 flex-shrink-0 w-60">
      {" "}
      <img src={image} className="w-60 h-60" alt={`${nombre}`} />
      <div className="text-start mt-2">
        {" "}
        <h5
          className={`font-ibm-plex text-2xl md:text-h5-desktop ${
            dark ? "text-gray126N" : "text-black"
          }`}
        >
          {nombre}
        </h5>
        <p className="font-ibm-plex text-gray26N text-p-desktop leading-tight mt-2">
          {puesto}
        </p>
      </div>
    </div>
  );
};

export default EquipoCard;
