import React from "react";
import Inspira from "./Inspira";
import EquipoExpandido from "./EquipoExpandido";

const Nosotros = () => {
  window.scrollTo(0, 0);
  return (
    <div>
      <Inspira />
      <EquipoExpandido />
    </div>
  );
};

export default Nosotros;
