import React from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <motion.nav
      className="bg-black flex justify-between items-center p-4"
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{
        type: "spring",
        stiffness: 120,
        damping: 10,
        duration: 1,
        delay: 1,
      }}
    >
      <div className="flex items-center">
        <Link to="/">
          <svg
            width="38"
            height="32"
            viewBox="0 0 38 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.9803 0C29.4875 0 38 8.18516 38 18.2884C38 23.7579 35.4917 28.6576 31.5416 32H6.45845C2.50835 28.6576 3.42992e-05 23.7579 3.42992e-05 18.2884C-0.0197162 8.18516 8.49275 0 18.9803 0Z"
              fill="white"
            />
          </svg>
        </Link>
      </div>
      <div>
        <Link to="/nosotros" className="mr-4 text-white hover:text-gray-300">
          NOSOTROS
        </Link>
        <Link
          to="/contacto"
          className="border border-white text-white px-4 py-2 rounded-full hover:bg-purple26N hover:border-purple26N"
        >
          CONTACTO
        </Link>
      </div>
    </motion.nav>
  );
};

export default Navbar;
