import React, { useEffect, useState } from "react";
import { supabaseClient } from "../config/supabase";
import EquipoCard from "./EquipoCard";

const EquipoExpandido = () => {
  const [equipoData, setEquipoData] = useState([]);

  useEffect(() => {
    const fetchEquipo = async () => {
      const { data, error } = await supabaseClient
        .from("equipo")
        .select("*")
        .order("id", { ascending: true });
      if (error) console.log("Error fetching equipo data:", error.message);
      else setEquipoData(data);
    };
    fetchEquipo();
  }, []);

  return (
    <div className="bg-black min-h-screen md:min-h-[50vh] relative overflow-hidden flex flex-col justify-center px-4 pb-6 pt-16">
      <div className="flex flex-col md:flex-row">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-pink26N font-ibm-plex">Equipo</h6>
        </div>
        <div className="basis-3/4">
          <h2 className="text-white font-druk-wide font-bold uppercase text-2xl md:text-4xl ">
            Somos lo que contamos
          </h2>
          <div className="flex flex-wrap justify-start mt-6">
            {equipoData.map((miembro, index) => (
              <div
                key={index}
                className="w-full sm:w-1/2 md:w-1/3 flex justify-center mt-4"
              >
                <EquipoCard
                  nombre={miembro.nombre}
                  puesto={miembro.puesto}
                  image={miembro.img_url}
                  dark
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EquipoExpandido;
