import React from "react";
import { motion } from "framer-motion";

const Hero = () => {
  return (
    <div className="bg-black flex flex-col justify-end items-center h-[90vh] relative overflow-hidden">
      <motion.h2
        className="text-white font-druk-wide font-bold uppercase text-3xl md:text-h2-desktop px-4 py-2 w-full "
        initial={{ y: 100, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{
          type: "spring",
          stiffness: 100,
          damping: 10,
          duration: 1,
          delay: 1,
        }}
      >
        Somos una agencia especializada en crear visual storytelling con sentido
        humano
      </motion.h2>
    </div>
  );
};

export default Hero;
