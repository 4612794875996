import "./App.css";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import Footer from "./components/Footer";
import Nosotros from "./components/Nosotros";
import ScrollLoadingScreen from "./components/ScrollLoadingScreen";
import Contacto from "./components/Contacto";

function App() {
  const [showHome, setShowHome] = useState(false);
  const location = useLocation();

  const isLaunched = process.env.REACT_APP_LAUNCH_WEBSITE === "true";

  useEffect(() => {
    if (location.pathname === "/" && !showHome) {
      setShowHome(false);
    } else {
      setShowHome(true);
    }
  }, [location.pathname, showHome]);

  if (!isLaunched) {
    return (
      <div className="flex items-center justify-center h-screen bg-black text-white">
        <h1 className="text-4xl">En construcción</h1>
      </div>
    );
  }

  return (
    <div className="overflow-hidden bg-black">
      {!showHome && location.pathname === "/" ? (
        <ScrollLoadingScreen setShowHome={setShowHome} />
      ) : (
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <Home />
                <Footer />
              </>
            }
          />
          <Route
            path="/nosotros"
            element={
              <>
                <Navbar />
                <Nosotros />
                <Footer />
              </>
            }
          />
          <Route path="/contacto" element={<Contacto />} />
        </Routes>
      )}
    </div>
  );
}

export default function MainApp() {
  return (
    <Router>
      <App />
    </Router>
  );
}
