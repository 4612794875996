import React, { useEffect, useState } from "react";
import { supabaseClient } from "../config/supabase";

const Clientes = () => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchClientes = async () => {
      const { data, error } = await supabaseClient.from("logos").select("*");
      if (error) console.log("Error fetching logos data:", error.message);
      else setLogos(data);
    };
    fetchClientes();
  }, []);
  return (
    <div className="bg-white min-h-screen md:min-h-[100vh] relative overflow-hidden px-4 py-6">
      <div className="flex flex-col md:flex-row mt-5">
        <div className="basis-1/4">
          <h6 className="text-h6-desktop text-purple26N font-ibm-plex">
            Clientes
          </h6>
        </div>
        <div className="basis-3/4">
          <h2 className="text-black font-druk-wide font-bold uppercase text-2xl md:text-4xl ">
            Somos parte de los relatos de:
          </h2>
        </div>
      </div>

      <div className="mt-8 grid grid-cols-2 md:grid-cols-4 gap-4">
        {logos.map((logo, index) => (
          <div key={index} className="w-full flex items-center justify-center">
            <img
              src={logo.img_url}
              alt={`${logo.nombre_cliente} logo`}
              className="max-w-full h-auto object-contain"
              style={{
                width: "160px",
                height: "120px",
              }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clientes;
