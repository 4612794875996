import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const ScrollLoadingScreen = ({ setShowHome }) => {
  const [step, setStep] = useState(0);

  useEffect(() => {
    const stepDurations = [200, 2000, 4000];
    let timeoutId;

    const showSteps = () => {
      let accumulatedDuration = 0;

      stepDurations.forEach((duration, index) => {
        accumulatedDuration += duration;
        setTimeout(() => {
          setStep(index + 1);
        }, accumulatedDuration);
      });

      timeoutId = setTimeout(() => {
        setShowHome(true);
        window.scrollTo(0, 0);
      }, accumulatedDuration + 3000);
    };

    showSteps();

    return () => {
      clearTimeout(timeoutId);
    };
  }, [setShowHome]);

  return (
    <div className="scroll-loading-screen min-h-[200vh] bg-black relative">
      <div className="fixed inset-0 flex flex-col items-center justify-center">
        <motion.div
          className="fixed top-1/2 transform -translate-y-1/2"
          style={{ height: "40px", marginBottom: "20px" }}
        >
          <motion.svg
            width="38"
            height="32"
            viewBox="0 0 38 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            initial={{ y: 100, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 100,
              damping: 10,
              duration: 0.5,
            }}
          >
            <path
              d="M18.9803 0C29.4875 0 38 8.18516 38 18.2884C38 23.7579 35.4917 28.6576 31.5416 32H6.45845C2.50835 28.6576 3.42992e-05 23.7579 3.42992e-05 18.2884C-0.0197162 8.18516 8.49275 0 18.9803 0Z"
              fill="white"
            />
          </motion.svg>
        </motion.div>

        <motion.div
          className="relative flex flex-col items-center text-center"
          initial={{ y: 100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{
            type: "spring",
            stiffness: 100,
            damping: 10,
            duration: 0.5,
          }}
        >
          {step === 2 && (
            <motion.h5
              className="text-h5-desktop text-white font-ibm-plex mt-28 mb-2 max-w-[80%] md:max-w-[60%] mx-auto transition-opacity duration-300" // Increased margin-top
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 10,
                duration: 0.5,
                delay: 0.2,
              }}
            >
              Las épocas cambian, las tendencias pasan, pero las historias
              permanecen.
            </motion.h5>
          )}

          {step === 3 && (
            <motion.h2
              className="text-white font-druk-wide font-bold uppercase text-3xl md:text-h2-desktop mt-44 mb-2 max-w-[100%] md:max-w-[100%] mx-auto text-center" // Increased margin-top
              style={{ lineHeight: "1.4" }}
              initial={{ y: 100, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{
                type: "spring",
                stiffness: 100,
                damping: 10,
                duration: 0.5,
                delay: 0.3,
              }}
            >
              En 26norte contamos historias que se ven
            </motion.h2>
          )}
        </motion.div>
      </div>
    </div>
  );
};

export default ScrollLoadingScreen;
